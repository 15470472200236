// Success
export const SUCCESS_PASSWORD_RESET =
  'Your password has been reset successfully'
export const SUCCESS_EMAIL_VERIFY = 'Please click the link sent to your email'
export const SUCCESS_VERIFY_CODE_RESEND =
  'A new verify code has been sent successfully'
export const SUCCESS_NAME_UPDATED = 'Your name has been updated successfully'
export const SUCCESS_PASSWORD_UPDATED =
  'Your password has been updated successfully'

// Errors - forms
export const ERROR_FIRSTNAME = 'Please provide a first name'
export const ERROR_LASTNAME = 'Please provide a last name'
export const ERROR_EMAIL = 'Please provide a valid email'
export const ERROR_MOBILE = 'Please provide a valid mobile'
export const ERROR_PASSWORD = 'Please provide a password'
export const ERROR_PASSWORD_MINLENGTH = 'Password length must be 8 or more'
export const ERROR_PASSWORD_MINLOWER =
  'Password must contain a lowercase letter'
export const ERROR_PASSWORD_MINUPPER =
  'Password must contain an uppercase letter'
export const ERROR_PASSWORD_MINNUMBER = 'Password must contain a number'
export const ERROR_PASSWORD_MINSYMBOL = 'Password must contain a symbol'
export const ERROR_PASSWORD_NEW = 'New password must be different to current'
export const ERROR_PASSWORD_CONFIRM = 'New passwords do not match'
export const ERROR_TERMS = 'Please agree to the terms and conditions'

// Errors - general
export const ERROR_PARAM_CLIENT_ID = `Missing query param 'client_id'`
export const ERROR_PARAM_REDIRECT_URL = `Missing query param 'redirect_url'`
export const ERROR_PARAM_SCOPE = `Missing query param 'scope'`
export const ERROR_PARAM_STATE = `Missing query param 'state'`
export const ERROR_PARAM_CHALLENGE_METHOD = `Invalid query param 'code_challenge_method'`
export const ERROR_PARAM_RESET_TOKEN = `Missing query param 'reset_token'`
export const ERROR_PARAM_VERIFY_TOKEN = `Missing query param 'verify_token'`
export const ERROR_APPLICATION_CONFIG =
  'Unable to load application for given client_id'
export const ERROR_GENERAL = 'Something went wrong. Please try again later'
