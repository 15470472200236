import React from 'react'
import AuthLayout from '../../components/auth-layout/auth-layout.component'
import Profile from '../../components/profile/profile.component'

const ProfilePage = () => {
  return (
    <AuthLayout title="Profile" disableLogoLink={true}>
      <Profile />
    </AuthLayout>
  )
}

export default ProfilePage
