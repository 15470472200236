import React from 'react'
import { Stack } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useNavigate } from 'react-router-dom'
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validateMobile,
  validatePassword,
  validatePasswordConfirm,
  validateTerms,
} from '../../lib/validate'
import { isError, isSuccess, SignupInitialRes } from 'ui-types'
import useAuthParams from '../../lib/hooks/use-auth-params'
import { SignupFormFields } from '../../lib/types'
import Input from '../input-field/input-field.component'
import Password from '../password-field/password-field.component'
import classes from './signup-form.module.scss'
import MobileField from '../mobile-field/mobile-field.component'
import useServerConfig from '../../lib/hooks/use-server-config'
import useFormError from '../../lib/hooks/use-form-error'
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js'
import { handleSignupInitial } from '../../lib/api/operations'
import SubmitButton from '../submit-button/submit-button.component'
import { PKCE_METHOD_PLAIN } from '../../lib/constants'
import useLoader from '../../lib/hooks/use-loader'
import Checkbox from '../checkbox-field/checkbox-field.component'
import useSignupState from '../../lib/hooks/use-signup-state'
import { Anchor } from '@mantine/core'

const SignupForm = () => {
  const { loading, withLoader } = useLoader()

  const config = useServerConfig()

  const navigate = useNavigate()

  const { signupState, setSignupState } = useSignupState()

  const {
    clientId,
    redirectUrl,
    scope,
    state,
    codeChallenge,
    codeChallengeMethod,
  } = useAuthParams({
    clientId: true,
    redirectUrl: true,
    scope: true,
    state: true,
  })

  const form = useForm({
    initialValues: {
      firstName: signupState?.firstName || '',
      lastName: signupState?.lastName || '',
      email: signupState?.email || '',
      mobile: signupState?.mobile || '',
      password: '',
      passwordConfirm: '',
      marketingAccepted: signupState?.marketingAccepted || false,
      termsAccepted: false,
    },
    validate: {
      firstName: validateFirstName,
      lastName: validateLastName,
      email: validateEmail,
      mobile: (v: string) => validateMobile(v, config?.countryCode),
      password: validatePassword,
      passwordConfirm: validatePasswordConfirm,
      termsAccepted: (v: boolean) =>
        (config?.termsAndConditionsUrl || config?.termsAndConditionsWording) &&
        validateTerms(v),
    },
  })

  const handleError = useFormError('Sign up', form)

  const handleSubmit = async ({ mobile, ...values }: SignupFormFields) => {
    // Merge mobile number with country code as needed
    const mobileNum = parsePhoneNumberFromString(
      mobile,
      config?.countryCode as CountryCode
    )

    const valuesToSubmit = {
      clientId,
      redirectUrl,
      mobile: mobileNum ? mobileNum.number : mobile,
      ...values,
    }

    const result = await withLoader<SignupInitialRes>(() =>
      handleSignupInitial(valuesToSubmit)
    )

    if (isError(result)) {
      handleError(result)
    }

    if (isSuccess(result)) {
      setSignupState(valuesToSubmit)

      const { verifyToken } = result.response

      const params: any = {
        verify_token: verifyToken,
        client_id: clientId,
        redirect_url: redirectUrl,
        scope: scope,
        state: state,
      }

      if (codeChallenge) {
        const method = codeChallengeMethod
          ? codeChallengeMethod
          : PKCE_METHOD_PLAIN

        params.code_challenge = codeChallenge
        params.code_challenge_method = method
      }

      navigate(`/signup-verify?${new URLSearchParams(params)}`)
    }
  }

  return (
    <form className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <Input
          label="First name"
          placeholder="Your first name"
          withAsterisk
          {...form.getInputProps('firstName')}
        />
        <Input
          label="Last name"
          placeholder="Your last name"
          withAsterisk
          {...form.getInputProps('lastName')}
        />
        <Input
          label="Email"
          placeholder="your@email.com"
          withAsterisk
          {...form.getInputProps('email')}
        />
        <MobileField
          label="Mobile"
          placeholder={`Your ${config?.countryCode} mobile`}
          withAsterisk
          {...form.getInputProps('mobile')}
        />
        <Password
          label="Password"
          placeholder="Your password"
          withAsterisk
          {...form.getInputProps('password')}
        />
        <Password
          label="Confirm password"
          placeholder="Your password again"
          withAsterisk
          {...form.getInputProps('passwordConfirm')}
        />
        {config?.privacyPolicyUrl && !config?.autoOptInMarketing && (
          <Checkbox
            mt="xs"
            data-testid="testid_privacyPolicy"
            defaultChecked={signupState?.marketingAccepted}
            {...form.getInputProps('marketingAccepted')}
          >
            <>
              Send me offers, deals or news.{' '}
              <Anchor href={config.privacyPolicyUrl} target="_blank" inherit>
                Privacy Policy
              </Anchor>
            </>
          </Checkbox>
        )}
        {config?.termsAndConditionsUrl &&
          !config?.termsAndConditionsWording && (
            <Checkbox
              withAsterisk={true}
              {...form.getInputProps('termsAccepted')}
            >
              <>
                Agree to our{' '}
                <Anchor
                  href={config.termsAndConditionsUrl}
                  target="_blank"
                  inherit
                >
                  Terms & Conditions
                </Anchor>
              </>
            </Checkbox>
          )}
        {config?.termsAndConditionsWording && (
          <Checkbox
            withAsterisk={true}
            {...form.getInputProps('termsAccepted')}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: config?.termsAndConditionsWording,
              }}
            ></span>
          </Checkbox>
        )}
      </Stack>
      <SubmitButton label="Sign up" fullWidth loading={loading} />
    </form>
  )
}

export default SignupForm
