import React, { createContext, ReactNode, useState } from 'react'

import { SignupFormFields, SignupState } from '../types'

type SignupStateContextProviderValue = {
  signupState: SignupState | null
  setSignupState: (values: SignupFormFields) => void
  clearSignupState: () => void
}

export const SignupStateContext = createContext(
  {} as SignupStateContextProviderValue
)

type Props = {
  children: ReactNode
  // Optional. Used for testing
  value?: SignupState
}

export const SignupStateProvider = ({ value, children }: Props) => {
  const [signupState, setSignupState] = useState<SignupState | null>(
    value || null
  )

  const handleSetSignupState = ({
    email,
    firstName,
    lastName,
    mobile,
    marketingAccepted,
  }: SignupFormFields) => {
    // Deliberately skip storing password
    setSignupState({
      email,
      firstName,
      lastName,
      mobile,
      marketingAccepted,
    })
  }

  const handleClearSignupState = () => {
    setSignupState(null)
  }

  return (
    <SignupStateContext.Provider
      value={{
        signupState: signupState,
        setSignupState: handleSetSignupState,
        clearSignupState: handleClearSignupState,
      }}
    >
      {children}
    </SignupStateContext.Provider>
  )
}

export default (): SignupStateContextProviderValue =>
  React.useContext(SignupStateContext)
