import React from 'react'
import { TextInput } from '@mantine/core'
import classes from './input-field.module.scss'
import { InputProps } from '../../lib/types'

const Input = ({ label, placeholder, withAsterisk, ...props }: InputProps) => {
  return (
    <TextInput
      label={label}
      placeholder={placeholder}
      withAsterisk={withAsterisk}
      classNames={{
        label: classes.inputLabel,
        input: classes.inputBox,
        error: classes.error,
      }}
      {...props}
    />
  )
}

export default Input
