import React, { useLayoutEffect, useState } from 'react'
import { RouterProvider } from 'react-router-dom'
import {
  CSSVariablesResolver,
  defaultVariantColorsResolver,
  MantineProvider,
  MantineTheme,
  MantineThemeOverride,
} from '@mantine/core'
import '@mantine/core/styles.css'
import '@mantine/notifications/styles.css'
import './index.scss'
import { Notifications } from '@mantine/notifications'
import router from './lib/router/router'
import { Helmet } from 'react-helmet'
import { mergeMantineTheme, DEFAULT_THEME } from '@mantine/core'
import { isSuccess } from 'ui-types'
import { ServerConfigProvider } from './lib/hooks/use-server-config'
import { ConfigRes } from 'ui-types'
import { handleGetConfig } from './lib/api/operations'
import { SignupStateProvider } from './lib/hooks/use-signup-state'

const App = () => {
  const [theme, setTheme] = useState<MantineTheme | undefined>()
  const [config, setConfig] = useState<ConfigRes>()

  const initTheme = async () => {
    let themeOverride: MantineThemeOverride = {}

    const result = await handleGetConfig()

    if (isSuccess(result)) {
      setConfig(result.response)

      // Add common theme styles here
      const configFont = result.response.fontFamily
        ? `'${result.response.fontFamily}', `
        : ''
      const buttonBackground = result.response.buttonColourFill || '#228be6'

      themeOverride = {
        fontFamily: `${configFont}'Poppins', sans-serif`,
        other: {
          initialised: true,
          logo: result.response.logo || null,
          containerRadius: result.response.containerRadius || '4px',
          buttonColour: buttonBackground,
          buttonRadius: result.response.buttonRadius || '4px',
          inputRadius: result.response.inputRadius || '4px',
          inputColourLabel: result.response.inputColourLabel || '#000000',
          inputColourBorder: result.response.inputColourBorder || '#ced4da',
          inputColourError: result.response.inputColourError || '#fa5252',
          faviconUrl: result.response.faviconUrl || null,
        },
        variantColorResolver: (input) => {
          const defaultResolvedColors = defaultVariantColorsResolver(input)

          if (input.variant === 'brand') {
            return {
              ...defaultResolvedColors,
              color: result.response.buttonColourLabel || '#ffffff',
              background: buttonBackground,
              hover: result.response.buttonColourHover || '#1c7ed6',
            }
          }

          return defaultResolvedColors
        },
      }

      // Set background colour
      document.body.style.backgroundColor = result.response.backgroundColour
    }

    const mergedTheme = mergeMantineTheme(DEFAULT_THEME, themeOverride)

    setTheme(mergedTheme)
  }

  const variableResolver: CSSVariablesResolver = (theme) => {
    const variables = {
      '--container-radius': theme.other.containerRadius,
      '--input-color-label': theme.other.inputColourLabel,
      '--input-color-border': theme.other.inputColourBorder,
      '--input-color-error': theme.other.inputColourError,
      '--input-radius': theme.other.inputRadius,
    }

    return {
      variables,
      light: variables,
      dark: variables,
    }
  }

  useLayoutEffect(() => {
    initTheme()
  }, [])

  if (!theme) {
    return null
  }

  return (
    <React.StrictMode>
      <MantineProvider theme={theme} cssVariablesResolver={variableResolver}>
        <ServerConfigProvider value={config}>
          <Helmet>
            {theme.other.faviconUrl && (
              <link
                rel="shortcut icon"
                href={theme.other.faviconUrl}
                type="image/x-icon"
              />
            )}
            {config?.googleAnalyticsAccount && (
              <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${config.googleAnalyticsAccount}`}
              ></script>
            )}
            {config?.googleAnalyticsAccount && (
              <script>
                {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', '${config.googleAnalyticsAccount}');
                `}
              </script>
            )}
          </Helmet>
          <Notifications
            position="top-right"
            zIndex={1000}
            autoClose={6000}
            limit={2}
          />
          <SignupStateProvider>
            <RouterProvider router={router} />
          </SignupStateProvider>
        </ServerConfigProvider>
      </MantineProvider>
    </React.StrictMode>
  )
}

export default App
