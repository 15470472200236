import React from 'react'
import { Button, useMantineTheme } from '@mantine/core'
import { SubmitButtonProps } from '../../lib/types'

const SubmitButton = ({ label, fullWidth, loading }: SubmitButtonProps) => {
  const theme = useMantineTheme()

  return (
    <Button
      type="submit"
      fullWidth={fullWidth}
      w={!fullWidth ? '150px' : undefined}
      loading={loading}
      mt={fullWidth ? 'xl' : undefined}
      variant="brand"
      radius={theme.other.buttonRadius}
    >
      {!loading && label}
    </Button>
  )
}

export default SubmitButton
