import axios, { AxiosError, AxiosResponse } from 'axios'
import { notifications } from '@mantine/notifications'
import { env } from '../../env'
import { createError, createSuccess } from 'ui-types'
import { HEADER_SESSION_ID, STORAGE_KEY_SESSION_ID } from '../constants'

const api = axios.create({
  baseURL: `${env.REACT_APP_API_DOMAIN}${env.REACT_APP_API_PATH}/`,
})

api.interceptors.request.use((config) => {
  const sessionId = localStorage.getItem(STORAGE_KEY_SESSION_ID)

  if (sessionId) {
    config.headers[HEADER_SESSION_ID] = sessionId
  }

  return config
})

export const get = (url: string) => {
  notifications.clean()
  return api.get(url)
}

export const post = (url: string, data: any) => {
  notifications.clean()
  return api.post(url, data)
}

export const asyncApiHandler = async (
  operation: () => Promise<AxiosResponse<any, any>>
) => {
  try {
    // Action passed in operation
    const result = await operation()

    const sessionId = result.headers[HEADER_SESSION_ID]

    // Store local session id when server returns via header
    if (sessionId) {
      localStorage.setItem(
        STORAGE_KEY_SESSION_ID,
        result.headers[HEADER_SESSION_ID]
      )
    }

    return createSuccess(result.data)
  } catch (e: any) {
    console.error(e)

    if (e instanceof AxiosError && e.code === AxiosError.ERR_BAD_REQUEST) {
      // Remove local session id when server confirms it is invalid
      if (e.response?.data.sessionInvalid) {
        localStorage.removeItem(STORAGE_KEY_SESSION_ID)
      }

      return createError(e.response?.data)
    }
  }

  return createError()
}
