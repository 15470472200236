import React from 'react'
import { Stack } from '@mantine/core'
import { AuthorizationRes, isError, isSuccess } from 'ui-types'
import useAuthParams from '../../lib/hooks/use-auth-params'
import classes from './signup-verify-form.module.scss'
import useFormError from '../../lib/hooks/use-form-error'
import { handleSignupVerify } from '../../lib/api/operations'
import { PKCE_METHOD_PLAIN } from '../../lib/constants'
import useLoader from '../../lib/hooks/use-loader'
import VerifyCodeForm from '../verify-code-form/verify-code-form.component'
import useSignupState from '../../lib/hooks/use-signup-state'

const SignupVerifyForm = () => {
  const { loading, withLoader } = useLoader()

  const { clearSignupState } = useSignupState()

  const {
    clientId,
    redirectUrl,
    scope,
    state,
    codeChallenge,
    codeChallengeMethod,
  } = useAuthParams({
    clientId: true,
    redirectUrl: true,
    scope: true,
    state: true,
  })

  const handleError = useFormError('Verify email')

  const handleSubmit = async (verifyCode: string, verifyToken: string) => {
    const result = await withLoader<AuthorizationRes>(() =>
      handleSignupVerify({
        clientId,
        redirectUrl,
        scope,
        state,
        codeChallenge,
        codeChallengeMethod,
        verifyToken,
        verifyCode,
      })
    )

    if (isError(result)) {
      handleError(result)
    }

    if (isSuccess(result)) {
      clearSignupState()

      const { redirectUrl } = result.response
      window.location.replace(redirectUrl)
    }
  }

  const provideSearchParams = () => {
    const params: any = {
      client_id: clientId,
      redirect_url: redirectUrl,
      scope: scope,
      state: state,
    }

    if (codeChallenge) {
      const method = codeChallengeMethod
        ? codeChallengeMethod
        : PKCE_METHOD_PLAIN

      params.code_challenge = codeChallenge
      params.code_challenge_method = method
    }

    return params
  }

  return (
    <Stack className={classes.fullWidth}>
      <VerifyCodeForm
        onSubmit={handleSubmit}
        provideSearchParams={provideSearchParams}
        fromRoute="/signup"
        fromLabel="signup"
        loading={loading}
      />
    </Stack>
  )
}

export default SignupVerifyForm
