import React from 'react'
import { Stack } from '@mantine/core'
import { useForm } from '@mantine/form'
import { validateEmail, validatePasswordSimple } from '../../lib/validate'
import { AuthorizationRes, isError, isSuccess } from 'ui-types'
import useAuthParams from '../../lib/hooks/use-auth-params'
import { LoginFormFields } from '../../lib/types'
import Input from '../input-field/input-field.component'
import Password from '../password-field/password-field.component'
import useFormError from '../../lib/hooks/use-form-error'
import { handleLogin } from '../../lib/api/operations'
import PersistQueryLink from '../persist-query-link/persist-query-link.component'
import SubmitButton from '../submit-button/submit-button.component'
import useLoader from '../../lib/hooks/use-loader'
import useSignupState from '../../lib/hooks/use-signup-state'

const LoginForm = () => {
  const { loading, withLoader } = useLoader()

  const { clearSignupState } = useSignupState()

  const {
    clientId,
    redirectUrl,
    scope,
    state,
    codeChallenge,
    codeChallengeMethod,
  } = useAuthParams({
    clientId: true,
    redirectUrl: true,
    scope: true,
    state: true,
  })

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: validateEmail,
      password: validatePasswordSimple,
    },
  })

  const handleError = useFormError('Login', form)

  const handleSubmit = async (values: LoginFormFields) => {
    const result = await withLoader<AuthorizationRes>(() =>
      handleLogin({
        clientId,
        redirectUrl,
        scope,
        state,
        codeChallenge,
        codeChallengeMethod,
        ...values,
      })
    )

    if (isError(result)) {
      handleError(result)
    }

    if (isSuccess(result)) {
      clearSignupState()

      const { redirectUrl } = result.response
      window.location.replace(redirectUrl)
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} style={{ width: '100%' }}>
      <Stack>
        <Input
          label="Email"
          placeholder="your@email.com"
          withAsterisk
          {...form.getInputProps('email')}
        />
        <Password
          label="Password"
          placeholder="Your password"
          withAsterisk
          {...form.getInputProps('password')}
        />
        <PersistQueryLink route="/password-reset">
          Forgot password?
        </PersistQueryLink>
      </Stack>
      <SubmitButton label="Login" fullWidth loading={loading} />
    </form>
  )
}

export default LoginForm
