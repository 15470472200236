import React, { useEffect, useState } from 'react'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import { Anchor } from '@mantine/core'
import { Link } from 'react-router-dom'
import { PersistQueryLinkProps } from '../../lib/types'

const PersistQueryLink = ({
  route,
  children,
  ...props
}: PersistQueryLinkProps & Record<string, any>) => {
  const [searchParams] = useSearchParams()
  const [queryStr, setQueryStr] = useState<URLSearchParams>(
    new URLSearchParams()
  )

  useEffect(() => {
    const query = createSearchParams(Object.fromEntries(searchParams.entries()))
    setQueryStr(query)
  }, [searchParams, setQueryStr])

  return (
    <Anchor
      component={Link}
      size="sm"
      underline="always"
      to={`${route}?${queryStr}`}
      {...props}
    >
      {children}
    </Anchor>
  )
}

export default PersistQueryLink
