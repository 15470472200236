export type SuccessOrErrorKind = 'success' | 'error'

interface Result<T> {
  kind: SuccessOrErrorKind
  response: T
}

export interface SuccessResult<T = void> extends Result<T> {
  kind: 'success'
}

export interface ErrorResult<T = void> extends Result<T> {
  kind: 'error'
}

export function createSuccess(): SuccessResult
export function createSuccess<T>(response: T): SuccessResult<T>
export function createSuccess<T = void>(response?: T): SuccessResult<T> {
  return {
    kind: 'success',
    response,
  } as SuccessResult<T>
}

export function createError(): ErrorResult
export function createError<T>(response: T): ErrorResult<T>
export function createError<T = void>(response?: T): ErrorResult<T> {
  return {
    kind: 'error',
    response,
  } as ErrorResult<T>
}

export type SuccessOrError<T = void, E = void> =
  | SuccessResult<T>
  | ErrorResult<E>

export const isSuccess = <T>(
  response: SuccessOrError<T, unknown>
): response is SuccessResult<T> => response?.kind === 'success'

export const isError = <E>(
  response: SuccessOrError<unknown, E>
): response is ErrorResult<E> => response?.kind === 'error'
