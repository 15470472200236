import React, { useEffect, useState } from 'react'
import {
  Anchor,
  Box,
  Center,
  Group,
  Progress,
  rem,
  Stack,
  Text,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { IconArrowLeft } from '@tabler/icons-react'
import { validateVerifyCode } from '../../lib/validate'
import SubmitButton from '../submit-button/submit-button.component'
import PersistQueryLink from '../persist-query-link/persist-query-link.component'
import Code from '../code-field/code-field.component'
import { VerifyCodeFormFields } from '../../lib/types'
import { handleResendVerifyCode } from '../../lib/api/operations'
import useAuthParams from '../../lib/hooks/use-auth-params'
import useFormError from '../../lib/hooks/use-form-error'
import { isError, isSuccess } from 'ui-types'
import useToast from '../../lib/hooks/use-toast'
import classes from './verify-code-form.module.scss'
import { SUCCESS_VERIFY_CODE_RESEND } from '../../lib/messages'

type Props = {
  onSubmit: (verifyCode: string, verifyToken: string) => void
  provideSearchParams: () => { [key: string]: string }
  loading: boolean
  fromRoute: string
  fromLabel: string
}

const VerifyCodeForm = ({
  onSubmit,
  provideSearchParams,
  loading,
  fromRoute,
  fromLabel,
}: Props) => {
  const [resendEnabled, setResendEnabled] = useState(false)
  const [progress, setProgress] = useState(0)
  const timer: any = React.useRef(null)

  const { showSuccess } = useToast()

  const { verifyToken, setSearchParams } = useAuthParams({
    verifyToken: true,
  })

  const form = useForm({
    initialValues: {
      verifyCode: '',
    },
    validate: {
      verifyCode: validateVerifyCode,
    },
  })

  const handleError = useFormError('Verify email', form)

  const handleSubmit = async ({ ...values }: VerifyCodeFormFields) => {
    onSubmit(values.verifyCode, verifyToken)
  }

  const handleResendCode = async () => {
    const result = await handleResendVerifyCode({
      verifyToken,
    })

    if (isError(result)) {
      handleError(result)
    }

    if (isSuccess(result)) {
      const params = provideSearchParams()

      params.verify_token = result.response.verifyToken

      // Update url query params with new verify_token
      setSearchParams(`?${new URLSearchParams(params)}`)

      showSuccess('Verify code', SUCCESS_VERIFY_CODE_RESEND)

      // Disable resend for 30 seconds
      resetProgress()
    }
  }

  const beginProgress = () => {
    timer.current = setInterval(() => {
      setProgress((prev) => {
        if (prev < 100) {
          return prev + 3.333
        }
        if (prev >= 100) {
          setResendEnabled(true)
        }
        return prev
      })
    }, 1000)
  }

  const resetProgress = () => {
    setResendEnabled(false)
    setProgress(0)
    clearInterval(timer.current)
    beginProgress()
  }

  useEffect(() => {
    if (!timer.current) {
      beginProgress()
    }

    return () => {
      // Reset timer.current so StrictMode's double load doesn't leave timer set with old value
      timer.current = clearInterval(timer.current)
    }
  }, [])

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack align="center">
        <Text size="sm">Please enter verify code from your email</Text>
        <Code {...form.getInputProps('verifyCode')} />
        <Anchor
          className={classes.resendButton}
          component="button"
          type="button"
          size="sm"
          underline="never"
          onClick={handleResendCode}
          disabled={!resendEnabled}
        >
          Resend a new code
          <Progress className={classes.resendProgress} value={progress} />
        </Anchor>
      </Stack>
      <Group justify="space-between" mt="xl">
        <PersistQueryLink route={fromRoute}>
          <Center>
            <IconArrowLeft
              style={{ width: rem(12), height: rem(12) }}
              stroke={1.5}
            />
            <Box ml={5}>Back to {fromLabel}</Box>
          </Center>
        </PersistQueryLink>
        <SubmitButton label="Verify email" loading={loading} />
      </Group>
    </form>
  )
}

export default VerifyCodeForm
