import React from 'react'
import AuthLayout from '../../components/auth-layout/auth-layout.component'
import SignupVerifyForm from '../../components/signup-verify-form/signup-verify-form.component'

const SignupVerifyPage = () => {
  return (
    <AuthLayout title="Email verification">
      <SignupVerifyForm />
    </AuthLayout>
  )
}

export default SignupVerifyPage
