import React from 'react'
import { Checkbox as CoreCheckbox } from '@mantine/core'
import classes from './checkbox-field.module.scss'
import { CheckboxFieldProps } from '../../lib/types'

const Checkbox = ({ withAsterisk, children, ...props }: CheckboxFieldProps) => {
  return (
    <CoreCheckbox
      label={
        <>
          {children}
          {withAsterisk && (
            <span className={classes.asterisk} aria-hidden="true">
              &#42;
            </span>
          )}
        </>
      }
      {...props}
    />
  )
}

export default Checkbox
