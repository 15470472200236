import { useState } from 'react'
import { ValidationError } from '../types'
import { UseFormReturnType } from '@mantine/form'
import { ErrorResult } from 'ui-types'
import { ERROR_GENERAL } from '../messages'
import useToast from './use-toast'

const useFormError = (
  label: string,
  parentForm?: UseFormReturnType<any, any>
) => {
  const { showError } = useToast()

  const [form] = useState<UseFormReturnType<any, any> | undefined>(parentForm)

  const handleError = (result: ErrorResult<void | ValidationError>) => {
    if (result.response) {
      const { field, message } = result.response
      if (form && field) {
        form.setFieldError(field, message)
        return
      } else if (message) {
        showError(`${label} error`, message)
        return
      }
    }

    showError(`${label} error`, ERROR_GENERAL)
  }

  return handleError
}

export default useFormError
