import React from 'react'
import LoginForm from '../../components/login-form/login-form.component'
import AuthLayout from '../../components/auth-layout/auth-layout.component'
import AuthLink from '../../components/auth-link/auth-link.component'

const LoginPage = () => {
  return (
    <AuthLayout title="Login">
      <LoginForm />
      <AuthLink
        message="Don't have an account?"
        route="/signup"
        linkText="Sign up"
      />
    </AuthLayout>
  )
}

export default LoginPage
