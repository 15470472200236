import React from 'react'
import { PinInput } from '@mantine/core'
import classes from './code-field.module.scss'
import { CodeProps } from '../../lib/types'

const Code = ({ ...props }: CodeProps) => {
  return (
    <PinInput
      type="number"
      length={6}
      placeholder=""
      autoFocus
      oneTimeCode
      data-testid="testid_verifyCode"
      classNames={{
        root: classes.root,
      }}
      {...props}
    />
  )
}

export default Code
