import React from 'react'
import { Box, Center, Group, rem } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useNavigate } from 'react-router-dom'
import { validateEmail } from '../../lib/validate'
import { handlePasswordResetInitial } from '../../lib/api/operations'
import { PasswordResetFormFields } from '../../lib/types'
import classes from './password-reset-form.module.scss'
import Input from '../input-field/input-field.component'
import PersistQueryLink from '../persist-query-link/persist-query-link.component'
import { IconArrowLeft } from '@tabler/icons-react'
import useAuthParams from '../../lib/hooks/use-auth-params'
import SubmitButton from '../submit-button/submit-button.component'
import useLoader from '../../lib/hooks/use-loader'
import { isError, isSuccess, PasswordResetInitialRes } from 'ui-types'
import { PKCE_METHOD_PLAIN } from '../../lib/constants'
import useFormError from '../../lib/hooks/use-form-error'
import useSignupState from '../../lib/hooks/use-signup-state'

const PasswordResetForm = () => {
  const { loading, withLoader } = useLoader()

  const navigate = useNavigate()

  const { clearSignupState } = useSignupState()

  const {
    clientId,
    redirectUrl,
    scope,
    state,
    codeChallenge,
    codeChallengeMethod,
  } = useAuthParams({
    clientId: true,
    redirectUrl: true,
    scope: true,
    state: true,
  })

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: validateEmail,
    },
  })

  const handleError = useFormError('Password reset', form)

  const handleSubmit = async ({ ...values }: PasswordResetFormFields) => {
    const result = await withLoader<PasswordResetInitialRes>(() =>
      handlePasswordResetInitial({ ...values })
    )

    if (isError(result)) {
      handleError(result)
    }

    if (isSuccess(result)) {
      clearSignupState()

      const params: any = {
        verify_token: result.response.verifyToken,
        client_id: clientId,
        redirect_url: redirectUrl,
        scope: scope,
        state: state,
      }

      if (codeChallenge) {
        const method = codeChallengeMethod
          ? codeChallengeMethod
          : PKCE_METHOD_PLAIN

        params.code_challenge = codeChallenge
        params.code_challenge_method = method
      }

      navigate(
        `/password-reset-verify?${Object.entries(params)
          .map((v) => v.join('='))
          .join('&')}`
      )
    }
  }

  return (
    <form className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
      <Input
        label="Email"
        placeholder="your@email.com"
        withAsterisk
        {...form.getInputProps('email')}
      />
      <Group justify="space-between" mt="lg">
        <PersistQueryLink route="/login">
          <Center>
            <IconArrowLeft
              style={{ width: rem(12), height: rem(12) }}
              stroke={1.5}
            />
            <Box ml={5}>Back to login</Box>
          </Center>
        </PersistQueryLink>
        <SubmitButton label="Reset password" loading={loading} />
      </Group>
    </form>
  )
}

export default PasswordResetForm
