import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import LoginPage from '../../pages/login/login.page'
import SignupPage from '../../pages/signup/signup.page'
import PasswordResetPage from '../../pages/password-reset/password-reset.page'
import NotFoundPage from '../../pages/not-found/not-found.page'
import SuccessPage from '../../pages/success/success.page'
import ErrorPage from '../../pages/error/error.page'
import authLoader from './auth.loader'
import logoutLoader from './logout.loader'
import ProfilePage from '../../pages/profile/profile.page'
import PasswordResetConfirmPage from '../../pages/password-reset-confirm/password-reset-confirm.page'
import PasswordResetVerifyPage from '../../pages/password-reset-verify/password-reset-verify.page'
import SignupVerifyPage from '../../pages/signup-verify/signup-verify.page'

const router = createBrowserRouter([
  {
    path: '/auth',
    loader: authLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/signup',
    element: <SignupPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/signup-verify',
    element: <SignupVerifyPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/logout',
    loader: logoutLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: '/password-reset',
    element: <PasswordResetPage />,
  },
  {
    path: '/password-reset-verify',
    element: <PasswordResetVerifyPage />,
  },
  {
    path: '/password-reset-confirm',
    element: <PasswordResetConfirmPage />,
  },
  {
    path: '/profile',
    element: <ProfilePage />,
  },
  {
    path: '/success',
    element: <SuccessPage />,
  },
  {
    path: 'error/:overrideError?',
    element: <ErrorPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
])

export default router
