import React, { useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Accordion,
  Anchor,
  Box,
  Center,
  Group,
  Loader,
  rem,
  useMantineTheme,
} from '@mantine/core'
import { Link } from 'react-router-dom'
import { IconArrowLeft } from '@tabler/icons-react'
import ProfilePasswordForm from '../profile-password-form/profile-password-form.component'
import classes from './profile.module.scss'
import ProfileNameForm from '../profile-name-form/profile-name-form.component'
import { useSearchParams } from 'react-router-dom'
import { isError, isSuccess } from 'ui-types'
import { handleCheckProfileUrls } from '../../lib/api/operations'
import { ERROR_GENERAL } from '../../lib/messages'
import useToast from '../../lib/hooks/use-toast'

const Profile = () => {
  const [searchParams] = useSearchParams()
  const theme = useMantineTheme()
  const [loading, setLoading] = useState(true)
  const { showError } = useToast()

  const navigate = useNavigate()

  const [validReturnUrl, setValidReturnUrl] = useState<string>('')
  const [validLogoutUrl, setValidLogoutUrl] = useState<string>('')

  const initUrls = async () => {
    if (validReturnUrl || validLogoutUrl) {
      return
    }

    const returnUrl = searchParams.get('return_url') || ''
    const logoutUrl = searchParams.get('logout_url') || ''

    const result = await handleCheckProfileUrls({ returnUrl, logoutUrl })

    if (isError(result)) {
      if (result.response?.sessionInvalid || result.response?.accountLocked) {
        showError('Profile error', result.response?.message)

        navigate(`/logout?logout_url=${logoutUrl}`)
      } else {
        const error = result.response?.message || ERROR_GENERAL

        throw navigate(`/error/${error}`)
      }
    }

    if (isSuccess(result)) {
      setValidReturnUrl(result.response.returnUrl)
      setValidLogoutUrl(result.response.logoutUrl)
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    initUrls()
  }, [])

  if (loading) {
    return (
      <Group h="433px">
        <Loader type="bars" color={theme.other.buttonColour} />
      </Group>
    )
  }

  return (
    <>
      <Group justify="space-between" className={classes.fullWidth}>
        <Anchor
          component={Link}
          size="sm"
          underline="always"
          to={validReturnUrl}
        >
          <Center>
            <IconArrowLeft
              style={{ width: rem(12), height: rem(12) }}
              stroke={1.5}
            />
            <Box ml={5}>Back</Box>
          </Center>
        </Anchor>
      </Group>
      <Accordion
        classNames={{
          root: classes.fullWidth,
          item: classes.item,
          label: classes.label,
        }}
        mt={'md'}
        variant="separated"
        defaultValue="Name"
      >
        <Accordion.Item key="Name" value="Name">
          <Accordion.Control>Name</Accordion.Control>
          <Accordion.Panel>
            <ProfileNameForm />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item key="Password" value="Password">
          <Accordion.Control>Password</Accordion.Control>
          <Accordion.Panel>
            <ProfilePasswordForm />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <Anchor
        component={Link}
        size="sm"
        underline="always"
        to={`/logout?logout_url=${validLogoutUrl}`}
        mt={'xl'}
      >
        Logout
      </Anchor>
    </>
  )
}

export default Profile
