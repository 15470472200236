import { useLayoutEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  ERROR_PARAM_CHALLENGE_METHOD,
  ERROR_PARAM_CLIENT_ID,
  ERROR_PARAM_REDIRECT_URL,
  ERROR_PARAM_RESET_TOKEN,
  ERROR_PARAM_SCOPE,
  ERROR_PARAM_STATE,
  ERROR_PARAM_VERIFY_TOKEN,
} from '../messages'
import { CodeChallengeMethod, RequiredQueryParams } from '../types'
import { PKCE_METHOD_PLAIN } from '../constants'

const useAuthParams = (required: RequiredQueryParams) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [clientId, setClientId] = useState<string>('')
  const [redirectUrl, setRedirectUrl] = useState<string>('')
  const [scope, setScope] = useState<string>('')
  const [state, setState] = useState<string>('')
  const [codeChallenge, setCodeChallenge] = useState<string>('')
  const [codeChallengeMethod, setCodeChallengeMethod] =
    useState<CodeChallengeMethod>(PKCE_METHOD_PLAIN)
  const [resetToken, setResetToken] = useState<string>('')
  const [verifyToken, setVerifyToken] = useState<string>('')
  const [returnUrl, setReturnUrl] = useState<string>('')
  const [logoutUrl, setLogoutUrl] = useState<string>('')

  useLayoutEffect(() => {
    const newClientId = searchParams.get('client_id') || ''
    const newRedirectUrl = searchParams.get('redirect_url') || ''
    const newScope = searchParams.get('scope') || ''
    const newState = searchParams.get('state') || ''
    const newCodeChallenge = searchParams.get('code_challenge') || ''
    const newCodeChallengeMethod =
      (searchParams.get('code_challenge_method') as CodeChallengeMethod) ||
      PKCE_METHOD_PLAIN
    const newResetToken = searchParams.get('reset_token') || ''
    const newVerifyToken = searchParams.get('verify_token') || ''
    const newReturnUrl = searchParams.get('return_url') || ''
    const newLogoutUrl = searchParams.get('logout_url') || ''

    if (required.clientId && !newClientId) {
      console.error(ERROR_PARAM_CLIENT_ID)
      throw new Error(ERROR_PARAM_CLIENT_ID)
    }

    if (required.redirectUrl && !newRedirectUrl) {
      console.error(ERROR_PARAM_REDIRECT_URL)
      throw new Error(ERROR_PARAM_REDIRECT_URL)
    }

    if (required.scope && !newScope) {
      console.error(ERROR_PARAM_SCOPE)
      throw new Error(ERROR_PARAM_SCOPE)
    }

    if (required.state && !newState) {
      console.error(ERROR_PARAM_STATE)
      throw new Error(ERROR_PARAM_STATE)
    }

    if (required.resetToken && !newResetToken) {
      console.error(ERROR_PARAM_RESET_TOKEN)
      throw new Error(ERROR_PARAM_RESET_TOKEN)
    }

    if (required.verifyToken && !newVerifyToken) {
      console.error(ERROR_PARAM_VERIFY_TOKEN)
      throw new Error(ERROR_PARAM_VERIFY_TOKEN)
    }

    if (
      codeChallengeMethod &&
      !['S256', 's256', 'plain'].includes(codeChallengeMethod)
    ) {
      console.error(ERROR_PARAM_CHALLENGE_METHOD)
      throw new Error(ERROR_PARAM_CHALLENGE_METHOD)
    }

    setClientId(newClientId)
    setRedirectUrl(newRedirectUrl)
    setScope(newScope)
    setState(newState)
    setCodeChallenge(newCodeChallenge)
    setCodeChallengeMethod(newCodeChallengeMethod)
    setResetToken(newResetToken)
    setVerifyToken(newVerifyToken)
    setReturnUrl(newReturnUrl)
    setLogoutUrl(newLogoutUrl)
  }, [searchParams])

  return {
    clientId,
    redirectUrl,
    scope,
    state,
    codeChallenge,
    codeChallengeMethod,
    resetToken,
    verifyToken,
    returnUrl,
    logoutUrl,
    setSearchParams,
  }
}

export default useAuthParams
