import React from 'react'
import { Stack } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { isError, isSuccess, PasswordResetVerifyRes } from 'ui-types'
import useAuthParams from '../../lib/hooks/use-auth-params'
import classes from './password-reset-verify-form.module.scss'
import useFormError from '../../lib/hooks/use-form-error'
import { handlePasswordResetVerify } from '../../lib/api/operations'
import { PKCE_METHOD_PLAIN } from '../../lib/constants'
import useLoader from '../../lib/hooks/use-loader'
import VerifyCodeForm from '../verify-code-form/verify-code-form.component'

const PasswordResetVerifyForm = () => {
  const { loading, withLoader } = useLoader()

  const navigate = useNavigate()

  const {
    clientId,
    redirectUrl,
    scope,
    state,
    codeChallenge,
    codeChallengeMethod,
  } = useAuthParams({
    clientId: true,
    redirectUrl: true,
    scope: true,
    state: true,
  })

  const handleError = useFormError('Verify email')

  const handleSubmit = async (verifyCode: string, verifyToken: string) => {
    const result = await withLoader<PasswordResetVerifyRes>(() =>
      handlePasswordResetVerify({
        verifyToken,
        verifyCode,
      })
    )

    if (isError(result)) {
      handleError(result)
    }

    if (isSuccess(result)) {
      const params = provideSearchParams()

      // Take current query params then add new reset_token
      params.reset_token = result.response.resetToken

      navigate(`/password-reset-confirm?${new URLSearchParams(params)}`)
    }
  }

  const provideSearchParams = () => {
    const params: any = {
      client_id: clientId,
      redirect_url: redirectUrl,
      scope: scope,
      state: state,
    }

    if (codeChallenge) {
      const method = codeChallengeMethod
        ? codeChallengeMethod
        : PKCE_METHOD_PLAIN

      params.code_challenge = codeChallenge
      params.code_challenge_method = method
    }

    return params
  }

  return (
    <Stack className={classes.fullWidth}>
      <VerifyCodeForm
        onSubmit={handleSubmit}
        provideSearchParams={provideSearchParams}
        fromRoute="/password-reset"
        fromLabel="password reset"
        loading={loading}
      />
    </Stack>
  )
}

export default PasswordResetVerifyForm
