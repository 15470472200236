import React, { useEffect } from 'react'
import { useParams, useRouteError } from 'react-router-dom'
import { Container, Title, Text, Group } from '@mantine/core'
import classes from './error.module.scss'
import { RouteError } from '../../lib/types'
import ErrorImage from './error-image'

const ErrorPage = () => {
  const error = useRouteError() as RouteError
  const { overrideError } = useParams()

  useEffect(() => {
    if (overrideError || error) {
      console.error(overrideError || error)
    }
  }, [])

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.inner}>
          <ErrorImage className={classes.image} />
          <div className={classes.content}>
            <Title className={classes.title}>Something went wrong</Title>
            <Text
              c="dimmed"
              size="lg"
              ta="center"
              className={classes.description}
            >
              Oops. An error has occurred while attempting to load the page you
              requested.
            </Text>
            {(overrideError || error) && (
              <Group justify="center">
                <i>{overrideError || error.statusText || error.message}</i>
              </Group>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default ErrorPage
