import { redirect } from 'react-router-dom'
import { handleLogout } from '../api/operations'
import { isError, isSuccess } from 'ui-types'
import { ERROR_GENERAL } from '../messages'
import { RouteLoaderProps } from '../types'
import { STORAGE_KEY_SESSION_ID } from '../constants'

const logoutLoader = async ({ request }: RouteLoaderProps) => {
  const paramEntries = new URL(request.url).searchParams.entries()
  const params = Object.fromEntries(paramEntries)

  const result = await handleLogout({
    logoutUrl: params.logout_url,
  })

  if (isError(result)) {
    const error = result?.response?.message || ERROR_GENERAL
    throw redirect(`/error/${error}`)
  }

  if (isSuccess(result)) {
    localStorage.removeItem(STORAGE_KEY_SESSION_ID)

    const { logoutUrl } = result.response

    return redirect(logoutUrl)
  }
}

export default logoutLoader
