import { useState } from 'react'
import { SuccessOrError } from 'ui-types'
import { RequestError } from '../types'

const useLoader = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const withLoader = async <T>(
    operation: (props?: any) => Promise<SuccessOrError<T, RequestError | void>>
  ) => {
    setLoading(true)

    const result = await operation()

    setLoading(false)

    return result
  }

  return {
    loading,
    withLoader,
  }
}

export default useLoader
