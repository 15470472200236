import React from 'react'
import AuthLayout from '../../components/auth-layout/auth-layout.component'
import PasswordResetVerifyForm from '../../components/password-reset-verify-form/password-reset-verify-form.component'

const PasswordResetVerifyPage = () => {
  return (
    <AuthLayout title="Email verification">
      <PasswordResetVerifyForm />
    </AuthLayout>
  )
}

export default PasswordResetVerifyPage
