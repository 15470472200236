import React from 'react'
import { Stack } from '@mantine/core'
import { useForm } from '@mantine/form'
import {
  validateNewPassword,
  validatePasswordConfirm,
  validatePasswordSimple,
} from '../../lib/validate'
import { handleProfilePasswordUpdate } from '../../lib/api/operations'
import { isError, isSuccess, SuccessRes } from 'ui-types'
import { ProfilePasswordFormFields } from '../../lib/types'
import Password from '../password-field/password-field.component'
import classes from './profile-password-form.module.scss'
import useFormError from '../../lib/hooks/use-form-error'
import useToast from '../../lib/hooks/use-toast'
import SubmitButton from '../submit-button/submit-button.component'
import useLoader from '../../lib/hooks/use-loader'
import { SUCCESS_PASSWORD_UPDATED } from '../../lib/messages'

const ProfilePasswordForm = () => {
  const { loading, withLoader } = useLoader()

  const { showSuccess } = useToast()

  const form = useForm({
    initialValues: {
      password: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    validate: {
      password: validatePasswordSimple,
      newPassword: (v: string, otherV: { password: string }) =>
        validateNewPassword(v, otherV.password),
      newPasswordConfirm: (v: string, otherV: { newPassword: string }) =>
        validatePasswordConfirm(v, { password: otherV.newPassword }),
    },
  })

  const handleError = useFormError('Password update', form)

  const handleSubmit = async ({ ...values }: ProfilePasswordFormFields) => {
    const result = await withLoader<SuccessRes>(() =>
      handleProfilePasswordUpdate({ ...values })
    )

    if (isError(result)) {
      handleError(result)
    }

    if (isSuccess(result)) {
      showSuccess('Password update', SUCCESS_PASSWORD_UPDATED)
    }
  }

  return (
    <form className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <Password
          label="Current password"
          placeholder="Your current password"
          withAsterisk
          {...form.getInputProps('password')}
        />
        <Password
          label="New password"
          placeholder="Your new password"
          withAsterisk
          {...form.getInputProps('newPassword')}
        />
        <Password
          label="Confirm new password"
          placeholder="Your new password again"
          withAsterisk
          {...form.getInputProps('newPasswordConfirm')}
        />
      </Stack>
      <SubmitButton label="Update password" fullWidth loading={loading} />
    </form>
  )
}

export default ProfilePasswordForm
