import { redirect } from 'react-router-dom'
import { isError, isSuccess } from 'ui-types'
import { ERROR_GENERAL } from '../messages'
import { RouteLoaderProps } from '../types'
import { handleInitAuthorization } from '../api/operations'

const authLoader = async ({ request }: RouteLoaderProps) => {
  const paramEntries = new URL(request.url).searchParams.entries()
  const params = Object.fromEntries(paramEntries)

  const result = await handleInitAuthorization(
    params.client_id,
    params.redirect_url,
    params.scope,
    params.state,
    params.code_challenge,
    params.code_challenge_method
  )

  if (isError(result)) {
    const error = result?.response?.message || ERROR_GENERAL
    throw redirect(`/error/${error}`)
  }

  if (isSuccess(result)) {
    const { redirectUrl } = result.response
    return redirect(redirectUrl)
  }
}

export default authLoader
