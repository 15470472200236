import React from 'react'
import { PasswordInput } from '@mantine/core'
import classes from './password-field.module.scss'
import { PasswordProps } from '../../lib/types'

const Password = ({
  label,
  placeholder,
  withAsterisk,
  ...props
}: PasswordProps) => {
  return (
    <PasswordInput
      label={label}
      placeholder={placeholder}
      withAsterisk={withAsterisk}
      classNames={{
        label: classes.inputLabel,
        input: classes.inputBox,
        error: classes.error,
      }}
      {...props}
    />
  )
}

export default Password
