import { notifications } from '@mantine/notifications'
import { useMantineTheme } from '@mantine/core'

const useToast = () => {
  const theme = useMantineTheme()

  const showSuccess = (title: string, message: string) => {
    notifications.show({
      color: 'green',
      title,
      message,
    })
  }

  const showError = (title: string, message: string) => {
    notifications.show({
      color: theme.other.inputColourError,
      title,
      message,
    })
  }

  return {
    showSuccess,
    showError,
  }
}

export default useToast
