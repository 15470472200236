import React from 'react'
import PasswordResetForm from '../../components/password-reset-form/password-reset-form.component'
import AuthLayout from '../../components/auth-layout/auth-layout.component'

const PasswordResetPage = () => {
  return (
    <AuthLayout title="Forgot your password?">
      <PasswordResetForm />
    </AuthLayout>
  )
}

export default PasswordResetPage
