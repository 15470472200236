import React, { useLayoutEffect, useState } from 'react'
import { Stack } from '@mantine/core'
import { useForm } from '@mantine/form'
import { validateFirstName, validateLastName } from '../../lib/validate'
import { isError, isSuccess, ProfileNameRes, SuccessRes } from 'ui-types'
import { ProfileNameFormFields } from '../../lib/types'
import Input from '../input-field/input-field.component'
import classes from './profile-name-form.module.scss'
import useFormError from '../../lib/hooks/use-form-error'
import {
  handleGetProfileName,
  handleProfileNameUpdate,
} from '../../lib/api/operations'
import SubmitButton from '../submit-button/submit-button.component'
import useToast from '../../lib/hooks/use-toast'
import useLoader from '../../lib/hooks/use-loader'
import { SUCCESS_NAME_UPDATED } from '../../lib/messages'

const ProfileNameForm = () => {
  const { loading, withLoader } = useLoader()

  const [currentNamesLoaded, setCurrentNamesLoaded] = useState<boolean>(false)
  const { showSuccess } = useToast()

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
    },
    validate: {
      firstName: validateFirstName,
      lastName: validateLastName,
    },
  })

  const handleError = useFormError('Name update', form)

  const handleSubmit = async ({ ...values }: ProfileNameFormFields) => {
    const result = await withLoader<SuccessRes>(() =>
      handleProfileNameUpdate({
        ...values,
      })
    )

    if (isError(result)) {
      handleError(result)
    }

    if (isSuccess(result)) {
      showSuccess('Name update', SUCCESS_NAME_UPDATED)
    }
  }

  const initNames = async () => {
    const result = await withLoader<ProfileNameRes>(() =>
      handleGetProfileName()
    )

    if (isSuccess(result)) {
      const { firstName, lastName } = result.response
      form.setValues({ firstName, lastName })
    }

    // Indicate that name retrieval has completed, whether successful or not
    setCurrentNamesLoaded(true)
  }

  useLayoutEffect(() => {
    initNames()
  }, [])

  return (
    <form className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <Input
          label="First name"
          placeholder={currentNamesLoaded ? 'Your first name' : 'Loading...'}
          withAsterisk
          disabled={!currentNamesLoaded}
          {...form.getInputProps('firstName')}
        />
        <Input
          label="Last name"
          placeholder={currentNamesLoaded ? 'Your last name' : 'Loading...'}
          withAsterisk
          disabled={!currentNamesLoaded}
          {...form.getInputProps('lastName')}
        />
      </Stack>
      <SubmitButton label="Update name" fullWidth loading={loading} />
    </form>
  )
}

export default ProfileNameForm
