import React from 'react'
import { Box, Center, Group, Stack, rem } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useNavigate } from 'react-router-dom'
import { validatePassword, validatePasswordConfirm } from '../../lib/validate'
import { isError, isSuccess, SuccessRes } from 'ui-types'
import { PasswordResetConfirmFormFields } from '../../lib/types'
import Password from '../password-field/password-field.component'
import classes from './password-reset-confirm-form.module.scss'
import useFormError from '../../lib/hooks/use-form-error'
import useToast from '../../lib/hooks/use-toast'
import { IconArrowLeft } from '@tabler/icons-react'
import { handlePasswordResetConfirm } from '../../lib/api/operations'
import useAuthParams from '../../lib/hooks/use-auth-params'
import SubmitButton from '../submit-button/submit-button.component'
import useLoader from '../../lib/hooks/use-loader'
import { PKCE_METHOD_PLAIN } from '../../lib/constants'
import PersistQueryLink from '../persist-query-link/persist-query-link.component'
import { SUCCESS_PASSWORD_RESET } from '../../lib/messages'

const PasswordResetConfirmForm = () => {
  const { loading, withLoader } = useLoader()

  const navigate = useNavigate()

  const { showSuccess } = useToast()

  const {
    clientId,
    redirectUrl,
    scope,
    state,
    codeChallenge,
    codeChallengeMethod,
    resetToken,
  } = useAuthParams({
    resetToken: true,
  })

  const loginLinkValid = !!clientId && !!redirectUrl && !!scope && !!state

  const form = useForm({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validate: {
      password: validatePassword,
      passwordConfirm: (v: string, otherV: { password: string }) =>
        validatePasswordConfirm(v, { password: otherV.password }),
    },
  })

  const handleError = useFormError('Password reset confirm', form)

  const handleSubmit = async ({
    ...values
  }: PasswordResetConfirmFormFields) => {
    const result = await withLoader<SuccessRes>(() =>
      handlePasswordResetConfirm({ resetToken, ...values })
    )

    if (isError(result)) {
      handleError(result)
    }

    if (isSuccess(result)) {
      // Only redirect when initiated from login, not when initiated from reset link
      if (loginLinkValid) {
        const params: any = {
          client_id: clientId,
          redirect_url: redirectUrl,
          scope: scope,
          state: state,
        }

        if (codeChallenge) {
          const method = codeChallengeMethod
            ? codeChallengeMethod
            : PKCE_METHOD_PLAIN

          params.code_challenge = codeChallenge
          params.code_challenge_method = method
        }

        navigate(
          `/login?${Object.entries(params)
            .map((v) => v.join('='))
            .join('&')}`
        )
      }

      showSuccess('Password reset', SUCCESS_PASSWORD_RESET)
    }
  }

  return (
    <form className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <Password
          label="New password"
          placeholder="Your new password"
          withAsterisk
          {...form.getInputProps('password')}
        />
        <Password
          label="Confirm new password"
          placeholder="Your new password again"
          withAsterisk
          {...form.getInputProps('passwordConfirm')}
        />
      </Stack>
      {loginLinkValid ? (
        <Group justify="space-between" mt="xl">
          <PersistQueryLink route={'/login'}>
            <Center>
              <IconArrowLeft
                style={{ width: rem(12), height: rem(12) }}
                stroke={1.5}
              />
              <Box ml={5}>Back to login</Box>
            </Center>
          </PersistQueryLink>
          <SubmitButton label="Reset password" loading={loading} />
        </Group>
      ) : (
        <SubmitButton label="Reset password" fullWidth loading={loading} />
      )}
    </form>
  )
}

export default PasswordResetConfirmForm
