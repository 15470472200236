import React, { createContext, ReactNode } from 'react'

import { ConfigRes } from 'ui-types'

type ConfigContextProviderValue = ConfigRes | undefined

export const ServerConfigContext = createContext(
  {} as ConfigContextProviderValue
)

type Props = {
  value?: ConfigRes
  children: ReactNode
}

export const ServerConfigProvider = ({ value, children }: Props) => {
  return (
    <ServerConfigContext.Provider value={value}>
      {children}
    </ServerConfigContext.Provider>
  )
}

export default (): ConfigContextProviderValue =>
  React.useContext(ServerConfigContext)
