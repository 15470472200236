import React from 'react'
import PasswordResetConfirmForm from '../../components/password-reset-confirm-form/password-reset-confirm-form.component'
import AuthLayout from '../../components/auth-layout/auth-layout.component'

const PasswordResetConfirmPage = () => {
  return (
    <AuthLayout title="Set your new password">
      <PasswordResetConfirmForm />
    </AuthLayout>
  )
}

export default PasswordResetConfirmPage
