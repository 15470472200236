import React, { useLayoutEffect } from 'react'
import { Paper, Title, Container, Image, useMantineTheme } from '@mantine/core'
import classes from './auth-layout.module.scss'
import { AuthLayoutProps } from '../../lib/types'
import { ERROR_APPLICATION_CONFIG } from '../../lib/messages'
import useServerConfig from '../../lib/hooks/use-server-config'
import { Helmet } from 'react-helmet'

const AuthLayout = ({ title, disableLogoLink, children }: AuthLayoutProps) => {
  const config = useServerConfig()
  const theme = useMantineTheme()

  const logoLinkAvailable = !disableLogoLink && !!config?.logoLinkUrl

  const handleLogoClick = () => {
    if (logoLinkAvailable) {
      window.location.replace(config.logoLinkUrl!)
    }
  }

  useLayoutEffect(() => {
    if (!config) {
      throw new Error(ERROR_APPLICATION_CONFIG)
    }
  }, [config])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container className={classes.container}>
        <Paper className={classes.paper} withBorder shadow="md">
          {theme.other.logo && (
            <Image
              className={`${classes.logo} ${
                logoLinkAvailable ? classes.pointer : ''
              }`}
              src={`${theme.other.logo}`}
              fit="contain"
              onClick={() => handleLogoClick()}
            />
          )}
          <Title className={classes.title}>{title}</Title>
          {children}
        </Paper>
      </Container>
    </>
  )
}

export default AuthLayout
