import React from 'react'
import { Text } from '@mantine/core'
import PersistQueryLink from '../persist-query-link/persist-query-link.component'
import { AuthLinkProps } from '../../lib/types'

const AuthLink = ({ message, route, linkText }: AuthLinkProps) => {
  return (
    <Text c="dimmed" size="sm" ta="center" mt="xl">
      {message && `${message} `}
      <PersistQueryLink route={route}>{linkText}</PersistQueryLink>
    </Text>
  )
}

export default AuthLink
