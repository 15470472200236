import { z } from 'zod'
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js'
import {
  ERROR_EMAIL,
  ERROR_FIRSTNAME,
  ERROR_LASTNAME,
  ERROR_MOBILE,
  ERROR_PASSWORD,
  ERROR_PASSWORD_CONFIRM,
  ERROR_PASSWORD_MINLENGTH,
  ERROR_PASSWORD_MINLOWER,
  ERROR_PASSWORD_MINNUMBER,
  ERROR_PASSWORD_MINSYMBOL,
  ERROR_PASSWORD_MINUPPER,
  ERROR_PASSWORD_NEW,
  ERROR_TERMS,
} from './messages'

export const validateFirstName = (v: string) =>
  !z.string().min(2).safeParse(v).success && ERROR_FIRSTNAME

export const validateLastName = (v: string) =>
  !z.string().min(2).safeParse(v).success && ERROR_LASTNAME

export const validateEmail = (v: string) =>
  !z.string().email().safeParse(v).success && ERROR_EMAIL

export const validateMobile = (v: string, countryCode?: string) =>
  !z
    .string()
    .refine(() => isValidPhoneNumber(v, `${countryCode}` as CountryCode))
    .safeParse(v).success && ERROR_MOBILE

export const validatePasswordSimple = (v: string) =>
  !z.string().min(1).safeParse(v).success && ERROR_PASSWORD

export const validatePassword = (v: string) => {
  if (!z.string().min(8).safeParse(v).success) {
    return ERROR_PASSWORD_MINLENGTH
  }
  if (!/[0-9]/.test(v)) {
    return ERROR_PASSWORD_MINNUMBER
  }
  if (!/[a-z]/.test(v)) {
    return ERROR_PASSWORD_MINLOWER
  }
  if (!/[A-Z]/.test(v)) {
    return ERROR_PASSWORD_MINUPPER
  }
  if (!/\W/.test(v)) {
    return ERROR_PASSWORD_MINSYMBOL
  }
}

export const validateNewPassword = (
  newPassword: string,
  originalPassword: string
) => {
  const error = validatePassword(newPassword)
  if (error) {
    return error
  }

  if (newPassword === originalPassword) {
    return ERROR_PASSWORD_NEW
  }
}

export const validatePasswordConfirm = (
  value: string,
  values: { password: string }
) => {
  if (value !== values.password) {
    return ERROR_PASSWORD_CONFIRM
  }
}

export const validateTerms = (v: boolean) =>
  !z.literal<boolean>(true).safeParse(v).success && ERROR_TERMS

export const validateVerifyCode = (v: string) =>
  !z.string().regex(/\d{6}/).length(6).safeParse(v).success
