declare global {
  interface Window {
    env: any
  }
}

type EnvType = {
  REACT_APP_API_DOMAIN: string
  REACT_APP_API_PATH: string
}
export const env: EnvType = { ...process.env, ...window.env }
