import {
  ConfigRes,
  AuthorizationRes,
  InitAuthorizationParams,
  LoginDto,
  LogoutDto,
  LogoutRes,
  PasswordUpdateDto,
  SuccessRes,
  PasswordResetConfirmDto,
  SignupInitialDto,
  SignupInitialRes,
  SignupVerifyDto,
  ResendVerifyCodeDto,
  ResendVerifyCodeRes,
  UserUpdateDto,
  ProfileNameRes,
  UrlCheckDto,
  UrlCheckRes,
  PasswordResetVerifyDto,
  PasswordResetVerifyRes,
  PasswordResetInitialRes,
  PasswordResetInitialDto,
  SuccessOrError,
} from 'ui-types'
import { PKCE_METHOD_PLAIN } from '../constants'
import { asyncApiHandler, get, post } from './api'
import { RequestError, ValidationError } from '../types'

export const handleGetConfig = async (): Promise<
  SuccessOrError<ConfigRes, void>
> => {
  return asyncApiHandler(() => get('local/config'))
}

export const handleInitAuthorization = async (
  clientId: string,
  redirectUrl: string,
  scope: string,
  state: string,
  codeChallenge?: string,
  challengeMethod?: string
): Promise<SuccessOrError<any, any>> => {
  return asyncApiHandler(() => {
    const params: InitAuthorizationParams = {
      client_id: encodeURIComponent(clientId),
      response_type: 'code',
      redirect_url: encodeURIComponent(redirectUrl),
      scope: encodeURIComponent(scope),
      state: encodeURIComponent(state),
    }

    if (codeChallenge) {
      const method = challengeMethod ? challengeMethod : PKCE_METHOD_PLAIN

      // Pass codeChallenge values as well, when provided
      params.code_challenge = encodeURIComponent(codeChallenge)
      params.code_challenge_method = encodeURIComponent(method)
    }

    return get(
      `local/auth/init?${Object.entries(params)
        .map((v) => v.join('='))
        .join('&')}`
    )
  })
}

export const handleSignupInitial = async (
  signup: SignupInitialDto
): Promise<SuccessOrError<SignupInitialRes, ValidationError | void>> => {
  return asyncApiHandler(() => post('local/auth/signup-initial', signup))
}

export const handleSignupVerify = async (
  signup: SignupVerifyDto
): Promise<SuccessOrError<AuthorizationRes, ValidationError | void>> => {
  return asyncApiHandler(() => post('local/auth/signup-verify', signup))
}

export const handleResendVerifyCode = async (
  resendVerifyCode: ResendVerifyCodeDto
): Promise<SuccessOrError<ResendVerifyCodeRes, ValidationError | void>> => {
  return asyncApiHandler(() =>
    post('local/auth/resend-verify-code', resendVerifyCode)
  )
}

export const handleLogin = async (
  login: LoginDto
): Promise<SuccessOrError<AuthorizationRes, ValidationError | void>> => {
  return asyncApiHandler(() => post('local/auth/login', login))
}

export const handleLogout = async (
  logout: LogoutDto
): Promise<SuccessOrError<LogoutRes, ValidationError | void>> => {
  return asyncApiHandler(() => post('local/auth/logout', logout))
}

export const handleCheckProfileUrls = async (
  checkUrls: UrlCheckDto
): Promise<SuccessOrError<UrlCheckRes, RequestError | void>> => {
  return asyncApiHandler(() => post('local/profile/url-check', checkUrls))
}

export const handleGetProfileName = async (): Promise<
  SuccessOrError<ProfileNameRes, void>
> => {
  return asyncApiHandler(() => get('local/profile/name'))
}

export const handleProfileNameUpdate = async (
  nameUpdate: UserUpdateDto
): Promise<SuccessOrError<SuccessRes, ValidationError | void>> => {
  return asyncApiHandler(() => post('local/profile/name-update', nameUpdate))
}

export const handleProfilePasswordUpdate = async (
  passwordUpdate: PasswordUpdateDto
): Promise<SuccessOrError<SuccessRes, ValidationError | void>> => {
  return asyncApiHandler(() =>
    post('local/profile/password-update', passwordUpdate)
  )
}

export const handlePasswordResetInitial = async (
  passwordReset: PasswordResetInitialDto
): Promise<SuccessOrError<PasswordResetInitialRes, ValidationError | void>> => {
  return asyncApiHandler(() =>
    post('local/auth/password-reset-initial', passwordReset)
  )
}

export const handlePasswordResetVerify = async (
  passwordResetVerify: PasswordResetVerifyDto
): Promise<SuccessOrError<PasswordResetVerifyRes, ValidationError | void>> => {
  return asyncApiHandler(() =>
    post('local/auth/password-reset-verify', passwordResetVerify)
  )
}

export const handlePasswordResetConfirm = async (
  passwordResetConfirm: PasswordResetConfirmDto
): Promise<SuccessOrError<SuccessRes, ValidationError | void>> => {
  return asyncApiHandler(() =>
    post('local/auth/password-reset-confirm', passwordResetConfirm)
  )
}
