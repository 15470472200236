import React from 'react'
import { Group, TextInput } from '@mantine/core'
import classes from './mobile-field.module.scss'
import { InputProps } from '../../lib/types'
import Input from '../input-field/input-field.component'
import useServerConfig from '../../lib/hooks/use-server-config'

const MobileField = ({
  label,
  placeholder,
  withAsterisk,
  ...props
}: InputProps) => {
  const config = useServerConfig()

  const countryCallingCode = config?.countryCode === 'AU' ? '+61' : '+64'

  return (
    <Group className={classes.mobileGroup}>
      <TextInput
        className={classes.mobileCountry}
        disabled
        label={label}
        withAsterisk={withAsterisk}
        value={countryCallingCode}
      />
      <Input
        className={classes.mobileNumber}
        label=""
        placeholder={placeholder}
        {...props}
      />
    </Group>
  )
}

export default MobileField
