import React from 'react'
import SignupForm from '../../components/signup-form/signup-form.component'
import AuthLayout from '../../components/auth-layout/auth-layout.component'
import AuthLink from '../../components/auth-link/auth-link.component'

const SignupPage = () => {
  return (
    <AuthLayout title="Sign up">
      <SignupForm />
      <AuthLink
        message="Already have an account?"
        route="/login"
        linkText="Login"
      />
    </AuthLayout>
  )
}

export default SignupPage
