import React from 'react'
import { Container, Title, Text } from '@mantine/core'
import NotFoundImage from './not-found-image'
import classes from './not-found.module.scss'

const NotFoundPage = () => {
  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.inner}>
          <NotFoundImage className={classes.image} />
          <div className={classes.content}>
            <Title className={classes.title}>Nothing to see here</Title>
            <Text
              c="dimmed"
              size="lg"
              ta="center"
              className={classes.description}
            >
              Oops. The page you are trying to open cannot be found.
            </Text>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default NotFoundPage
